import styled from 'styled-components'

export default styled.div`
  width: 80px;
  background: white;
  .sidebar {
    height: 100%;
    width: 100%;
    #logo-home {
      width: 80px;
      height: 80px;
      img {
        &:hover {
          cursor: pointer;
        }
      }
    }
    .site-wrapper {
      width: 100%;
      height: calc(100% - 160px);
      position: relative;
      overflow-y: auto;
      &::before {
        content: '';
        width: calc(100% - 20px);
        height: 1px;
        background: ${(props) => props.theme.greyDisabled};
        position: absolute;
        left: 10px;
        top: 0px;
      }
      &::after {
        content: '';
        width: calc(100% - 20px);
        height: 1px;
        background: ${(props) => props.theme.greyDisabled};
        position: absolute;
        left: 10px;
        bottom: 0px;
      }
      .list-inactive-site-container {
        height: 100%;
        overflow-y: auto;
        &.shrink {
          height: calc(100% - 340px);
        }
      }
      .site-menu {
      }
    }
    .bottom-menu-wrapper {
      width: 80px;
      height: 80px;
    }
  }
`
