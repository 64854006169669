/* eslint-disable default-case */
/* eslint-disable multiline-ternary */
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import LogoIcon from '../../asset/images/sidebar/logo.svg'
import SidebarStyled from './styledComponent'
import ButtonPopUp from '../ButtonPopUp/Loadable'
import SiteMenu from '../SiteMenu/Loadable'
import MESSAGE from './message'
import { ENGLISH_LANGUAGE, THAI_LANGUAGE } from '../../utils'

const FEATURE_SITE = 'sites'
const FEATURE_PROJECT = 'projects'

class Sidebar extends PureComponent {
  state = {
    failureModalData: {
      show: false,
      title: '',
      message: '',
      onOverLayClose: undefined
    }
  }

  handleSidebarMenuClick = (path) => {
    this.props.onPageChanged(path)
  }

  handleLogout() {
    this.props.onLogout()
  }

  changeLanguage(value) {
    this.props.onLanguageChanged(value)
  }

  getMessage(property) {
    const msg = MESSAGE[this.props.currentUser?.language]
    return msg[property]
  }

  handleLogoClicked = () => {
    this.handleSidebarMenuClick('/home/sites')
  }

  renderLogo() {
    return (
      <div className="flex justify-center align-items-center" id="logo-home" onClick={this.handleLogoClicked}>
        <img src={LogoIcon} alt="" width={40} height={40} />
      </div>
    )
  }

  navigate(path) {
    this.props.onPageChanged(`/home/${path}`)
  }

  handlePopupChanged = (value) => {
    switch (value) {
      case ENGLISH_LANGUAGE:
      case THAI_LANGUAGE:
        this.changeLanguage(value)
        break
      case 'project-management':
        this.navigate('projects')
        break
      case 'user-management':
        this.navigate('user-management')
        break
      case 'api-keys':
        this.navigate('api-keys')
        break
      case 'sign-out':
        this.handleLogout()
        break
    }
  }

  renderSettingBtn() {
    const isAdmin = this.props.currentUser.admin
    const isManager = this.props.currentUser.manager
    const isUser = !(isAdmin || isManager)
    let navigatorData = [
      { text: this.getMessage('project_management'), value: 'project-management' },
      { text: this.getMessage('user_management'), value: 'user-management' },
      { text: this.getMessage('api_keys'), value: 'api-keys' }
    ]
    const languageData = [
      { text: this.getMessage('eng'), value: ENGLISH_LANGUAGE },
      { text: this.getMessage('tha'), value: THAI_LANGUAGE }
    ]
    const bottomMenuData = [{ text: this.getMessage('sign_out'), value: 'sign-out' }]
    if (isManager) {
      navigatorData = navigatorData.filter((navData) => navData.value !== 'api-keys')
    }
    return (
      <ButtonPopUp
        id="btn-setting"
        className="setting-button"
        userData={this.props.currentUser}
        navigatorData={isUser ? [] : navigatorData}
        languageData={languageData}
        bottomMenuData={bottomMenuData}
        onPopUpOptionChange={this.handlePopupChanged}
        language={this.props.currentUser.language}
      />
    )
  }

  renderSidebarSetting() {
    return <div className="bottom-menu-wrapper">{this.renderSettingBtn()}</div>
  }

  convertListSite(listProject) {
    const prepared = listProject.reduce((prev, current) => {
      const sites = current.sites.map((siteData) => Object.assign({}, siteData, { project_id: current.project_id }))
      return [...prev, ...sites]
    }, [])
    return prepared
  }

  renderListSites() {
    const feature = this.props.location.pathname.split('/')[2]
    const projectID = this.props.location.pathname.split('/')[3]
    const activeSiteId = this.props.location.pathname.split('/')[4]
    const listSite = this.convertListSite(this.props.listProject).filter((siteData) => siteData.project_id === Number(projectID))
    const activeSite = listSite.find((siteData) => siteData.project_id === Number(projectID) && siteData.site_id === Number(activeSiteId))
    const listInactiveSite = listSite.filter((siteData) => !(siteData.project_id === Number(projectID) && siteData.site_id === Number(activeSiteId)))
    const renderActiveSite =
      feature === FEATURE_SITE && activeSite ? (
        <div className="active-site-container">
          <SiteMenu
            key={`${activeSite.project_id}-${activeSite.site_id}`}
            siteObject={activeSite}
            onPageChanged={this.handleSidebarMenuClick}
            isActive={true}
            language={this.props.currentUser.language}
            {...this.props}
          />
        </div>
      ) : null
    const renderInActiveListSite = listInactiveSite.map((siteObj) => {
      return (
        <SiteMenu
          key={`${siteObj.project_id}-${siteObj.site_id}`}
          siteObject={siteObj}
          onPageChanged={this.handleSidebarMenuClick}
          isActive={false}
          language={this.props.currentUser.language}
          {...this.props}
        />
      )
    })
    const listInactiveClassName = activeSite ? 'list-inactive-site-container shrink' : 'list-inactive-site-container'
    const shouldRenderListSites = (feature !== FEATURE_SITE || activeSiteId) && feature !== FEATURE_PROJECT

    return (
      <div className="site-wrapper" id="site-menu-wrapper">
        {shouldRenderListSites ? renderActiveSite : null}
        <div className={listInactiveClassName}>{shouldRenderListSites ? renderInActiveListSite : null}</div>
      </div>
    )
  }

  render() {
    return (
      <SidebarStyled>
        <div className="sidebar">
          {this.renderLogo()}
          {this.renderListSites()}
          {this.renderSidebarSetting()}
        </div>
      </SidebarStyled>
    )
  }
}

Sidebar.defaultProps = {
  currentUser: {
    email: '',
    admin: false,
    manager: false,
    language: 'EN'
  }
}

Sidebar.propTypes = {
  location: PropTypes.object.isRequired,
  currentUser: PropTypes.object,
  listProject: PropTypes.array.isRequired,
  onPageChanged: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
  onLanguageChanged: PropTypes.func.isRequired
}

export default Sidebar
