const MESSAGE = {
  EN: {
    sign_out: 'Sign Out',
    project_management: 'Project Management',
    user_management: 'User Management',
    api_keys: 'API Keys',
    language: 'Language',
    eng: 'English',
    tha: 'Thai',
    dashboard_menu_text: 'Dashboard',
    violation_menu_text: 'Violation'
  },
  TH: {
    sign_out: 'ออกจากระบบ',
    project_management: 'จัดการโปรเจกต์',
    user_management: 'จัดการรายชื่อ',
    api_keys: 'API Keys',
    language: 'ภาษา',
    eng: 'อังกฤษ',
    tha: 'ไทย',
    dashboard_menu_text: 'แดชบอร์ด',
    violation_menu_text: 'การฝ่าฝืน'
  }
}

export default MESSAGE
